<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <b-link class="brand-logo" :to="{ name: 'login' }">
        <img :src="logo" alt="" />

        <h2 class="brand-text text-primary ml-1">HBase</h2>
      </b-link>

      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Register V2" />
        </div>
      </b-col>

      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-1"> Privacy & Policy </b-card-title>
          <b-card-text class="mb-2">
            <!-- -->
          </b-card-text>
        </b-col>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
  data: () => ({
    logo: require('@/assets/images/logo/qcs_logo.png'),
    imgUrl: require('@/assets/images/pages/register-v2-dark.svg'),
  }),
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
